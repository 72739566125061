import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './style/css/inter.css';
import './style/css/main.css';

const searchParams = new URLSearchParams(window.location.search);

let token = process.env.REACT_APP_TOKEN || '';
const partner = searchParams.get('partner') || '';
const staged = process.env.REACT_APP_STAGED === 'true';

const getToken = searchParams.get('app-token') || '';
if (process.env.NODE_ENV === 'development' && !!getToken) {
    token = getToken;
}

console.log('******* DROP CONSOLE.LOG not working')

ReactDOM.render(
  <React.StrictMode>
    <App token={token} partner={partner} staged={staged}/>
  </React.StrictMode>,
  document.getElementById('root')
);
